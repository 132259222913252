@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'compagnon';
    font-weight: 600;
    font-style: normal;
    src: url("../fonts/Compagnon-Medium.woff2") format('woff2');
  }

  html, body{
    scroll-behavior: smooth;
    max-width: 100vw;
    overflow-x: hidden;
    width: 100vw;
  }

  body {
    max-width: 100vw;
    margin: 0px;
    background: radial-gradient(27% 24.02% at 59.08% 72.72%, rgba(62, 99, 221, 0.2) 0%, rgba(217, 226, 252, 0.08) 100%) , radial-gradient(32.58% 27.94% at 27.69% 70.26%, rgba(62, 99, 221, 0.15) 0%, rgba(240, 244, 255, 0.045) 100%) , radial-gradient(28.13% 28.13% at 77.68% 15.63%, rgba(62, 99, 221, 0.15) 0%, rgba(240, 244, 255, 0) 100%) , radial-gradient(50.64% 35.73% at 40.89% 24.77%, rgba(238, 157, 43, 0.15) 30.59%, rgba(255, 236, 188, 0.015) 100%) , #FEFDFB;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    min-height: 100vh;

    &.dark{
      background: radial-gradient(27% 24.02% at 59.08% 72.72%, rgba(39, 62, 137, 0.2) 0%, rgba(19, 22, 32, 0.1) 100%) , radial-gradient(32.58% 27.94% at 27.69% 70.26%, rgba(47, 78, 178, 0.15) 0%, rgba(21, 25, 45, 0.075) 100%) , radial-gradient(28.13% 28.13% at 77.68% 15.63%, rgba(47, 78, 178, 0.15) 0%, rgba(21, 25, 45, 0.075) 100%) , radial-gradient(50.64% 35.73% at 40.89% 24.77%, rgba(130, 78, 0, 0.15) 0.66%, rgba(105, 63, 5, 0.075) 100%) , #131620 !important;
      background-size: 400% 400% !important;
      animation: gradient 15s ease infinite;
    }
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }