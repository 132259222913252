@use "_variables" as var;

.TIM{

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;

    .TIM-background-cylindre{
        grid-row: 1;
        grid-column: 1;
        z-index: -1;
        position: relative;
        right: -57%;
        top: 58%;
        color: #D9E2FC;

        &.dark{
            color: #d9e2fc85;
        }
    }

.TIM-main {
    grid-row: 1;
    grid-column: 1;
    margin-left: 7vw;
    margin-top: 100px;

    h2 {
        font-size: 14px;
        font-family: var.$font-family-compagnon;
        line-height: 20px;
        color: var.$light-neutrals-text-default;

        &.dark{
            color: var.$dark-neutrals-text-default;
        }

    }

    .TIM-content-grid {
        margin-top: 30px;
        display: grid;
        grid-template-columns: 50% auto;
        column-gap: 5%;
        height: auto;

        .TIM-week-grid {
            display: flex;
            flex-direction: column;

            .TIM-week-selector {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                button {
                    background-color: transparent;
                    border: none;
                    font-family: var.$font-familiy-raleway;
                    color: var.$light-neutrals-text-subtle;
                    line-height: 23px;
                    font-size: 16px;
                    font-weight: 400;
                    padding: 0px;

                    &.dark{
                        color: var.$dark-neutrals-text-subtle;
                    }

                    &.active {
                        font-weight: 600;
                        color: var.$light-neutrals-text-default;
                        &.dark{
                            color: var.$dark-neutrals-text-default;
                        }
                    }
                }
            }

            .TIM-week-arrow-container{
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 40px;
                margin-top: 20px;

                button{
                    cursor: pointer;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background-color: var.$light-secondary-foreground-default;

                    width: 40px;
                    height: 40px;
                    border-radius: 100px;

                    border: 1px solid var.$light-secondary-border-separator ;

                    color: var.$light-secondary-text-subtle;

                    &.dark{
                        color: var.$dark-secondary-text-subtle;
                        border: 1px solid var.$dark-secondary-border-separator ;
                        background-color: var.$dark-secondary-foreground-default;
                    }
                }

                :nth-child(1) svg{
                    transform: rotate(90deg);
                }
                :nth-child(2) svg{
                    transform: rotate(-90deg);
                }
            }

            .TIM-musicalement{
                display: grid;
                grid-template-columns: 100%;
                grid-template-rows: 100%;
                padding-top: 50px;

                :nth-child(n){
                    grid-row: 1;
                    grid-column: 1;
                    width: fit-content;
                }
                svg:nth-child(1){
                    
                }
                svg:nth-child(2){
                    margin-top: 45px;
                    mix-blend-mode: multiply;
                }
            }
        }

        //day program //////////////

        .TIM-program-container{
            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: 100%;

            .TIM-verticalBar{
                margin-left: 11px;
                grid-column: 1;
                grid-row: 1;
                min-height: 500px;
                width: 1px;
                background: linear-gradient(var.$light-neutrals-text-subtle, var.$light-neutrals-foreground-active);

                &.dark{
                    background: linear-gradient(var.$dark-neutrals-text-subtle, var.$dark-neutrals-foreground-active);
                }
            }

            .TIM-item-list{
                grid-column: 1;
                grid-row: 1;
                display: flex;
                flex-direction: column;

                .TIM-item{
                    display: flex;
                    flex-direction: column;
                    margin-top: 30px;

                    &.dark{
                        color: white !important;
                    }

                    :nth-child(1){
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        svg{
                            margin-right: 20px;
                        }
                        
                        p{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin: 0px;
                            font-family: var.$font-familiy-raleway;

                            a{
                            margin-top: 0px;
                            margin-left: 5px;
                            color: black;
                        
                            &.dark{
                                color: var.$dark-neutrals-text-default;
                            }}
                        }
                    }
                    
                    img{
                        margin-top: 10px;
                        width: 10vw;
                        margin-left: 50px;
                    }
                    
                }
            }
        }
    }
}
}

@media screen and (max-width: 1000px) {
.TIM{
    .TIM-background-cylindre{
        display: none;
    }
    .TIM-main{
        margin-right: 7vw;
        .TIM-content-grid{
            display: flex;
            flex-direction: column;
            .TIM-week-grid{
                .TIM-week-selector{
                    width: 100%;
                }
                .TIM-musicalement{
                    display: none;
                }
            }
            .TIM-program-container{
                .TIM-verticalBar{
                    min-height: 100px;
                }
            }
        }
        
    }
}
}