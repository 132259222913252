@use "_variables" as var;

.APO-tag{
    padding: 8px 16px;
    border: 1px solid var.$light-neutrals-border-default;
    border-radius: 99px;
    background-color: var.$light-neutrals-border-separator;
    font-size: 14px;
    color: var.$light-secondary-text-subtle;
    font-weight: 700;
    transition: all 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .APO-tag-check{
        max-width: 0px;
        transition: all 0.5s;
    }

    &:hover{
        border-color: var.$light-neutrals-border-hover;
    }

    &.selected{
        background-color: var.$light-secondary-foreground-primary-default;
        border-color:var.$light-secondary-foreground-primary-default;
        color: white;
        .APO-tag-check{
           max-width: 100px;
        }
    }
}

#APO-main{
    display: grid;
    grid-template-columns: 35% 55%;
    gap : 10%;
    margin-top: 125px;
    margin-left: 7vw;
    margin-right: 7vw;
    font-family: var.$font-familiy-raleway;

    #APO-text{
        font-size: 16px;

        h2{
            font-family: var.$font-family-compagnon;
            font-size: 16px;
            margin-bottom: 30px;

            &.dark{
                color: var.$dark-neutrals-text-default;
            }
        }

        input{
            border-radius: 30px;
            min-width: 140px;
            width: fit-content;
            padding: 8px;
            border: 1px solid var.$light-neutrals-border-default;
            color: var.$light-neutrals-text-subtle;
            margin-bottom: 8px;
            background-color: var.$light-neutrals-border-separator;

            &:focus{
                outline: none;
                border-color: var.$light-pirmary-border-hover;
                box-shadow: 0px 1px 4px rgba(252, 153, 45, 0.3);
            }

            &.dark{
                border: 1px solid var.$dark-secondary-border-default;
                color: var.$dark-neutrals-text-subtle; 
                background-color: var.$dark-secondary-background-default;
            }
        }

        .APO-taglist{

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 8px;
        }

        p{
            &.dark{
                color: var.$dark-neutrals-text-default; 
            }
        }
    }

    #APO-podcasts-list{
        display: grid;
        grid-template-columns: 45% 45%;
        column-gap: 3%;
        row-gap: 50px;
        margin-bottom: 150px;

        .APO-podcast-bigItem{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 75%;
            color: black;
            text-decoration: none;
            
            &.dark{
                color: var.$dark-neutrals-text-default;
            }

            a{
                width: 100%;
                display: flex;
                justify-content: center;
                img{
                    margin-top: 0;
                    width: 100%;
                    image-rendering: smooth;
                    height: 100%;
                    object-fit: fill;
                    z-index: -1;
                    max-width: 150px;
                    max-height: 150px;
                    border-radius: 20px;
                    $border-width : 2px;
                    -webkit-box-shadow: $border-width $border-width 0px $border-width var.$light-primary-border-default; 
                    box-shadow: $border-width $border-width 0px $border-width var.$light-primary-border-default;
                }
            }

            img{
                margin-top: 20px;
                overflow: hidden;
                object-fit: cover;
                width: 150px;
                height: 5px;
            }
            h4{
                font-size: 14px;
                font-family: var.$font-family-compagnon;
                text-align: left;
                width: 100%;
                text-transform: uppercase;
                margin-bottom: 0px;

            }

            button:not(.APO-tag){
                display: flex;
                justify-content: center;
                align-items: center;
                color : var.$light-primary-text-subtle;
                background-color: var.$light-primary-background-subtle;
                padding : 8px 8px 8px 8px;
                border-radius: 99px;
                border: 1px solid var.$light-primary-foreground-primary-default;
                $border-width : 1px;
                -webkit-box-shadow: $border-width $border-width 0px $border-width var.$light-primary-border-default; 
                box-shadow: $border-width $border-width 0px $border-width var.$light-primary-border-default;
                cursor : pointer;
                margin-top: 20px;
                transition: all 0.5s;
                font-weight: 700;
                font-size: 12px;

                svg{
                    height: 14px;
                }

                &:hover{
                    background-color: var.$light-primary-foreground-hover ;
                }

            }

            p{
                margin-top: 10px;
                max-height: 100px;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 12px;

                &.dark{
                    color: var.$dark-neutrals-text-subtle;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    #APO-main{
        grid-template-columns: 100%;
        width: 82vw;
        row-gap: 10px;

        #APO-text{
            width: 100%;
            input{
                width: 78vw;
            }
            .APO-taglist{
                display: none;
            }
        }

        #APO-podcasts-list{
            grid-template-columns: 100%;
            width: 100%;

            .APO-podcast-bigItem{
                flex-direction: column;
                width: auto;

                .APO-podcast-img-container{
                    height: min-content;
                    min-height: 0px;
                    border-bottom: 0px;
                    padding-bottom: 0px;
                    margin-bottom: auto;
                    margin-top: 5px;
                    width: min-content;

                    img{
                        width: 100px;
                        max-width: none;
                        mask-image: none;
                    }
                }

                .APO-taglist{
                    display: none;
                }
            }
        }
    }
}