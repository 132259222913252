@use "_variables" as var;

#PRO-main{
    margin-top: 50px;
    margin-bottom: 175px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 7vw;
    margin-right: 7vw;

    &.dark{
        color: var.$dark-primary-text-default;
    }

    h2{
        font-family: var.$font-family-compagnon;
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 600;
        margin-top: 100px;
    }

    #PRO-projet-container{
        display: flex;
        flex-direction: row;
        justify-content: left;
        margin-top: 75px;

        #PRO-monkey{
            height: 150px;
            margin-right: 15vw;
        }

        #PRO-projet-text-Container{
            display: flex;
            flex-direction: column;

            p{
                line-height: 24px;
                width: 35vw;
                font-size: 16px;
                font-family: var.$font-familiy-raleway;
            }
        }
    }

    #PRO-team-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 75px;

        p{
            line-height: 24px;
            margin-left: 5vw;
            width: 35vw;
            font-size: 16px;
            font-family: var.$font-familiy-raleway;
        }

        #PRO-team{
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;

            img{
                grid-row: 1;
                grid-column: 1;
                width: 10vw;

                &:nth-child(1){
                    margin-left: 0vw;
                }

                &:nth-child(2){
                    margin-top: 75px;
                    margin-left: 8vw;
                }

                &:nth-child(3){
                    margin-top: 75px;
                    margin-left: 16vw;
                }

                &:nth-child(4){
                    margin-left: 24vw;
                }
            }
            
        }
    }

    #PRO-sponsors-container{
        display: flex;
        flex-direction: column;
        justify-content: left;

        p{
            width: 50vw;
            line-height: 24px;
            font-size: 16px;
            font-family: var.$font-familiy-raleway;
        }

        #PRO-sponsors-list{

            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: scroll;
            width: 75%;
            margin: auto;
            gap: 100px;
            margin-top: 50px;

            .PRO-sponsors-item{
                display: flex;
                flex-direction: column;
                text-decoration: none;

                img{
                    width: 8vw;
                    height: 8vw;
                    object-fit: contain;
                }

                h4{
                    text-align: center;
                    font-family: var.$font-family-compagnon;
                    font-size: 16px;
                    line-height: 22.4px;
                    font-weight: 600;
                    color: black;

                    &.dark{
                        color: var.$dark-primary-text-default;
                    }
                }
            }
        }
    }

    
@media screen and (max-width: 1000px) {

    #PRO-sponsors-list{

        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: hidden;
        width: 95%;
        margin: auto;
        gap: 100px;
        margin-top: 50px;

        .PRO-sponsors-item{
            display: flex;
            flex-direction: column;
            text-decoration: none;

            img{
                width: 50vw;
                height: 50vw;
                object-fit: contain;
            }

            h4{
                text-align: center;
                font-family: var.$font-family-compagnon;
                font-size: 16px;
                line-height: 22.4px;
                font-weight: 600;
                color: black;

                &.dark{
                    color: var.$dark-primary-text-default;
                }
            }
        }
    }
}
        
}