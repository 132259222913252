@use "_variables" as var;

#PLA-main{
    display: flex;
    flex-direction: row;
    margin-top: 150px;
    margin-left: 7vw;
    margin-right: 7vw;
    gap :5vw;

    #PLA-text-container{
        font-family: var.$font-familiy-raleway;
        font-size: 16px;

        h2{
            font-family: var.$font-family-compagnon;

            &.dark{
                color: var.$dark-neutrals-text-default;
            }
        }

        p{
            &.dark{
                color: var.$dark-neutrals-text-subtle;
            }
        }

        .PLA-input-group{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 20vw;
            margin-top: 50px;
            min-width: 330px;

            .PLA-input-container{
            display: flex;
            flex-direction: column;
            gap: 10px;

                label{
                    &.dark{
                        color: var.$dark-neutrals-text-default;
                    }
                }

                input{
                    border-radius: 8px;
                    min-width: 140px;
                    width: fit-content;
                    padding: 8px;
                    border: 1px solid var.$light-neutrals-border-default;
                    color: grey;

                    &.dark{
                        border: 1px solid var.$dark-secondary-border-default;
                        color: var.$dark-neutrals-text-subtle; 
                        background-color: var.$dark-secondary-background-default;
                    }
                }
            }
        }
    }
}

#PLA-track-container{
    margin-top: 70px;
    min-width: 44vw;
    border: 1px solid var.$light-neutrals-border-default;
    border-radius: 8px;
    background-color: white;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    margin-bottom: 125px;

    &.dark{
        border: 1px solid var.$dark-secondary-border-default;
        color: var.$dark-neutrals-text-subtle; 
        background-color: var.$dark-secondary-background-default;
    }

    .PLA-trackItem-container{
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        width: 100%;
        border-bottom: 2px dashed grey;
        font-size: 16px;
        font-family: var.$font-familiy-raleway;
        padding-top: 20px;
        padding-bottom: 10px;
        gap: 20px;

        .PLA-littleCircle{
            background-color: grey;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            margin-top: auto;
            margin-bottom: auto;
            min-width: 10px;
        }

        p{
            font-size: 14px;
            margin-top: auto;
            margin-bottom: auto;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;

            &:nth-child(1){
                min-width: 50px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    #PLA-main{
        flex-direction: column;
    }
}