@use "_variables" as var;

.WEL-main{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    min-height: 90vh;

    .WEL-backgroud-glob{
        grid-row: 1;
        grid-column: 1;
        position: relative;
        right: -83%;
        width: 35vw;
        color: #FFF9ED;
        opacity: 0.6;
        top: -13%;
        z-index: -1;

        &.dark{
            color: #1C274F;
        }
    }

    .WEL-background-oval{
        grid-row: 1;
        grid-column: 1;
        position: relative;
        width: 35vw;
        color: #ffebc4;
        opacity: 0.6;
        top: 39%;
        z-index: -1;

        &.dark{
            color :#1F2C5C;
        }
    }
}


.WEL-content-grid{
    display: grid;
    grid-template-columns: 50vw 40vw 5vw;
    grid-template-rows: 100%;
    grid-row: 1;
    grid-column: 1;
    padding-left: 5vw;


    .WEL-content-text{
        margin-top: 300px;
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;

        #WEL-mobile-monkey{display: none;}

        svg:nth-child(1){
            color: var.$light-primary-text-default;
            margin-bottom: 30px;

            &.dark{
                color: var.$dark-secondary-text-default;
            }
        }

        p{
            margin: 0px;
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: 0.01em;
            font-family: var.$font-familiy-raleway;
            color: var.$light-neutrals-text-default;

            &.dark{
                color: var.$dark-neutrals-text-default;
            }
        }

        button{
            margin-top: 16px;
            width: 208px;
            height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            background-color: var.$light-secondary-foreground-default;
            border: 1px solid var.$light-secondary-border-separator;
            border-radius: 8px;
            color: var.$light-secondary-text-subtle;

            &.dark{
                color: var.$dark-primary-foreground-primary-hover;
            }

            &.dark{
                background-color: var.$dark-primary-foreground-default ;
                border-color: var.$dark-primary-border-separator;
            }

            p{
                font-family: var.$font-familiy-raleway;
                font-size: 16px;
                font-weight: 700;
                color: inherit;

                &.dark{
                    color: var.$dark-primary-foreground-primary-hover;
                }
    
            }
        }
    }

    .WEL-social{

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div{
            transform: rotate(-90deg);
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 300px;

            a{
                font-family: var.$font-family-compagnon;
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                text-decoration: none;
                color: var.$light-neutrals-text-subtle;

                &.dark{
                    color: var.$dark-neutrals-text-subtle;
                }
            }
        }
    }

    .WEL-title{
        margin-top: 158px;
        margin-left: auto;
        margin-right: auto;
        height: fit-content;

        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 100%;

        svg{
            grid-row: 1;
            grid-column: 1;
            width: 100%;
            height: 37vh;
        }

        .WEL-bigLector{
            background-color: #FFB224;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            height: 11vw;
            align-self: center;
            justify-self: center;
            margin-left: 52%;
            grid-row: 1;
            grid-column: 1;
            width: 11vw;
            border-radius: 1000px;
            max-width: 190px;
            max-height: 190px;
            margin-bottom: 25%;

            &.dark{
                background-color: var.$dark-primary-foreground-primary-default;
                border: 5px solid var.$dark-primary-border-default;
            }

            svg{
                margin-top: 0px;
                width: 25%;
                height: 25%;
            }
        }
    }
}

.WEL-arrow-container{
    grid-row: 1;
    grid-column: 1;

    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    width: min-content;
    height: min-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 65vh;
    color: #C1C8CD;
    z-index: -1;

    :nth-child(n){
        grid-row: 1;
        grid-column: 1;
    }

    #stamp-circle{
        width: 8vw;
        fill: var.$light-neutrals-border-hover;
        transition: all 1s;
        transform: rotate(0deg);
        opacity: 0.3;

        &.dark{
            fill : #C1C8CD !important;
            color: #C1C8CD !important;
        }

        &:hover{
            transform: rotate(180deg);
            color: var.$light-secondary-foreground-primary-default !important;
            opacity: 1;
        }
    }

    #arrow-stamp{
        color: var.$light-neutrals-foreground-primary-hover;
        font-size: 2vw;
        margin: auto;
    }
}

@media screen and (max-width: 1000px) {

    .WEL-main{

        display: flex;
        flex-direction: column;
        min-height: 0px;

        .WEL-backgroud-glob{
            display: none;
        }

        .WEL-background-oval{
            display: none;
        }

        .WEL-content-grid{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px;
            width: 100%;
            max-width: none;

            .WEL-title{
                visibility: hidden;
                display: none
            }
            
            .WEL-content-text{
                max-width: none;
                margin-top: 50px;
                margin-left: 7vw;
                margin-right: 7vw;

                #WEL-desktop-monkey{
                    display: none;
                }

                .WEL-content-text-sub{
                    margin-top: 20px;
                    display: flex;
                    flex-direction: row; 
                    justify-content: space-between;
                    align-items: center;

                    #WEL-mobile-monkey{
                        display: flex;
                        width: 25vw;
                        height: fit-content;
                        margin: 0px;
                    }
                }
            }
            .WEL-social{
                visibility: hidden;
                display: none;
            }
        }

        .WEL-arrow-container{
            margin-top: 5vh;

            #stamp-circle{
                width: 50vw;
            }

            #arrow-stamp{
                font-size: 10vw;
            }
        }
    }
}