///////////////////////////// light theme////////////////////////////

//primary

$light-primary-background-default : #FEFDFB;
$light-primary-background-subtle : #FFF9ED;
$light-primary-foreground-default : #FFF4D5;
$light-primary-foreground-hover : #FFF4D5;
$light-primary-foreground-active : #FFF4D5;
$light-primary-foreground-primary-default : #FFB224;
$light-primary-foreground-primary-hover : #FFB224;
$light-primary-border-separator : #FFD386;
$light-primary-border-default : #F3BA63;
$light-pirmary-border-hover : #EE9D2B;
$light-primary-text-subtle: #AD5700;
$light-primary-text-default: #4E2009;

//secondary

$light-secondary-background-default : #FDFDFE;
$light-secondary-backround-default : #F8FAFF;
$light-secondary-foreground-default : #F0F4FF;
$light-secondary-foreground-hover : #E6EDFE;
$light-secondary-foreground-active : #D9E2FC;
$light-secondary-foreground-primary-default : #3E63DD;
$light-secondary-foreground-primary-hover : #3A5CCC;
$light-secondary-border-separator : #C6D4F9;
$light-secondary-border-default : #AEC0F5;
$light-secondary-border-hover : #8DA4EF;
$light-secondary-text-subtle: #053194;
$light-secondary-text-default: #101D46;

//neutrals

$light-neutrals-background-default : #FBFCFD;
$light-neutrals-backround-default : #F8F9FA;
$light-neutrals-foreground-default : #F1F3F5;
$light-neutrals-foreground-hover : #ECEEF0;
$light-neutrals-foreground-active : #E6E8EB;
$light-neutrals-foreground-primary-default : #889096;
$light-neutrals-foreground-primary-hover : #7E868C;
$light-neutrals-border-separator : #F1F3F5;
$light-neutrals-border-default : #D7DBDF;
$light-neutrals-border-hover : #C1C8CD;
$light-neutrals-text-subtle: #687076;
$light-neutrals-text-default: #11181C;

///////////////////////////// dark theme////////////////////////////

//primary

$dark-primary-background-default : #131620;
$dark-primary-backround-default : #15192D;
$dark-primary-foreground-default : #192140;
$dark-primary-foreground-hover : #1C274F;
$dark-primary-foreground-active : #1F2C5C;
$dark-primary-foreground-primary-default : #3E63DD;
$dark-primary-foreground-primary-hover : #5373E7;
$dark-primary-border-separator : #22346E;
$dark-primary-border-default : #273E89;
$dark-pirmary-border-hover : #2F4EB2;
$dark-primary-text-subtle: #849DFF;
$dark-primary-text-default: #EEF1FD;

//secondary

$dark-secondary-background-default : #1F1300;
$dark-secondary-backround-default : #271700;
$dark-secondary-foreground-default : #341C00;
$dark-secondary-foreground-hover : #3F2200;
$dark-secondary-foreground-active : #4A2900;
$dark-secondary-foreground-primary-default : #FFB224;
$dark-secondary-foreground-primary-hover : #FFCB47;
$dark-secondary-border-separator : #573300;
$dark-secondary-border-default : #693F05;
$dark-secondary-border-hover : #824E00;
$dark-secondary-text-subtle: #F1A10D;
$dark-secondary-text-default: #FEF3DD;

//neutrals

$dark-neutrals-background-default : #151718;
$dark-neutrals-backround-default : #1A1D1E;
$dark-neutrals-foreground-default : #202425;
$dark-neutrals-foreground-hover : #26292B;
$dark-neutrals-foreground-active : #2B2F31;
$dark-neutrals-foreground-primary-default : #697177;
$dark-neutrals-foreground-primary-hover : #787F85;
$dark-neutrals-border-separator : #313538;
$dark-neutrals-border-default : #3A3F42;
$dark-neutrals-border-hover : #4C5155;
$dark-neutrals-text-subtle: #9BA1A6;
$dark-neutrals-text-default: #ECEDEE;

/////////////////////////////fonts////////////////////////////

$font-family-compagnon: "compagnon", sans-serif;
$font-weights-compagnon : 600;
$font-familiy-raleway : "Raleway", sans-serif;