@use "_variables" as var;

//components 

.donation-btn{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    width: 164px;
    height: 38px;
    border: 1px solid var.$light-primary-foreground-primary-default;
    box-shadow: 3px 3px 0px var.$light-primary-foreground-primary-default;
    border-radius: 100px;
    background-color: var.$light-primary-background-default;

    text-align: center;
    font-family: var.$font-familiy-raleway;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    color: var.$light-primary-text-subtle;

    &.dark{
        border: 1px solid var.$dark-primary-foreground-primary-default;
        box-shadow: 0px 0px 0px var.$light-primary-foreground-primary-default;
        background-color: var.$dark-primary-foreground-primary-default;
        color: white;
    }
}

.themeSwitch-btn{

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var.$light-secondary-foreground-default;

    width: 40px;
    height: 40px;
    border-radius: 100px;

    border: 1px solid var.$light-secondary-border-separator ;

    color: var.$light-secondary-text-subtle;

    &.dark{
        background-color: var.$dark-primary-foreground-default !important;
        border-color: var.$dark-primary-border-separator !important;
        color: var.$dark-primary-foreground-primary-hover !important;
    }
}

//page

.Nav-container{
    height: 40px;
    margin-left: 7vw;
    margin-top: 45px;
    display: flex;
    justify-content: space-between;
    width: 90vw;
    
    nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 3vw;
        letter-spacing: 0.04em;
    }

    .navLink{
        font-family: var.$font-family-compagnon;
        font-weight: var.$font-weights-compagnon;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none;
        color: var.$light-neutrals-text-subtle;

        &.dark{
            color: var.$dark-neutrals-text-subtle;
        }
    }
}

@media screen and (max-width: 1000px) {
    .Nav-container{
        visibility: hidden;
    }
}