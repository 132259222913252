@use "_variables" as var;
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');

.TM-main{
    position:fixed;
    top: 0px;
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: var.$light-primary-background-subtle;
    border-bottom: 1px solid var.$light-primary-border-separator;
    visibility: hidden;
    opacity: 0;
    transition: opacity 1.5s, height 1s ease-in-out;
    height: 76px;
    overflow: hidden;
    z-index: 100;

    a{
        color: var.$light-neutrals-text-default;
    }

    &.dark{
        background-color: var.$dark-primary-backround-default;
    }

    &.avaible{
        visibility: visible;
        opacity: 1;
    }

    &.active{
        height: 100vh;
    }

    .TM-header-container{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 7vw;
        padding-right: 7vw;
        padding-top: 14px;
        padding-bottom: 14px;

        .TM-header-rightPart{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

        .TM-header-close-container{
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            width: 40px;
            height: 40px;
            margin-left: 15px;
            border: none;
            background-color: transparent;

            span{
                grid-row: 1;
                grid-column: 1;
                transition: margin-top 1s, width 1s, transform 1s;
                height: 1px;
                width: 100%;
                background-color:  var.$light-neutrals-text-default;
                transform: none;

                &.dark{
                    background-color: var.$dark-neutrals-text-default;
                }
            }

            #TM-close1{
                margin-top: 15px;

                &.active{
                    margin-top: 20px;
                    transform: rotate(45deg) !important;
                }
            }

            #TM-close2{
                margin-top: 25px;
                width: 75%;

                &.active{
                    margin-top: 20px;
                    transform: rotate(-45deg) !important;
                    width: 100%;
                }
            }
        }
        }
    }

    .TM-dropDown-container{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items : center;
        height: 100%;
        min-height: 60vh;
        padding-bottom: 10vh;
        padding-top: 10vh;

        a{
            font-family: var.$font-family-compagnon;
            color: var.$light-neutrals-text-subtle;
            text-decoration: none;
            line-height: 140%;
            letter-spacing: 0.03em;
            font-size: 23px;
        }

        .TM-dropdown-illustration{
            display: grid;
            grid-template-columns: 10vw;
            grid-template-rows: 10vw;
            color: var.$dark-neutrals-text-default;

            :nth-child(n){
                grid-row: 1;
                grid-column: 1;
            }

            #TM-monkey{
                width: 50%;
                height: 50%;
                padding-left: 10%;
                margin: auto;
            }

            #TM-stamp{
                width: 100%;
                height: 100%;
                color: var.$light-secondary-text-subtle ;
                animation: infinit-loop 10s linear infinite;
            }

            @keyframes infinit-loop {
                from{
                    transform: rotate(0deg);
                }
                to{
                    transform: rotate(360deg);
                }
            }
        }

        .TM-dropdown-social{
            width: 30vw;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            gap: 15px;

            a{
                font-size: 14px;
            }
        }

        .TM-dev{
            *{
                font-family: var.$font-family-compagnon;
                font-size: 16px;
                margin: 0px;
                padding: 0px;
                letter-spacing: normal;
                line-height: normal;
                color: var.$light-neutrals-text-subtle;
                font-weight: 900;
                text-transform:uppercase;
            }

            svg{
                font-size: 30px;
            }

            border-radius: 8px;
            padding: 10px 20px 10px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 30px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .TM-main{
            visibility: visible !important;
            opacity: 1 !important;
    }
}