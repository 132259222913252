@use "_variables" as var;
.EV{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;

    .EV-background-halfGlob{
        grid-row: 1;
        grid-column: 1;
        z-index: -1;
        position: relative;
        top: 44%;
        left: -2%;
        color: #D9E2FC42;
    }

.EV-main{
    grid-row: 1;
    grid-column: 1;
    margin-left: 7vw;
    margin-right: 7vw;
    margin-top: 100px;
    padding-bottom: 150px;

    h2 {
        font-size: 14px;
        font-family: var.$font-family-compagnon;
        line-height: 20px;
        color: var.$light-neutrals-text-default;

        &.dark{
            color: var.$dark-neutrals-text-default;
        }

    }

    .EV-main-container{
        margin-top: 30px;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto;
    
    
        .EV-horizontalBar{
            margin-top: 11px;
            grid-row: 1;
            grid-column: 1;
            width: 100%;
            height: 1px;
            background : linear-gradient(90deg, var.$light-neutrals-text-subtle, var.$light-neutrals-foreground-active);

            &.dark{
                background : linear-gradient(90deg, var.$dark-neutrals-text-subtle, var.$dark-neutrals-foreground-active);
            }
        }
        
        .EV-list-container{
            grid-row: 1;
            grid-column: 1;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-y: scroll;
            scroll-snap-type: x mandatory;
            align-items: center;
            justify-content: flex-start;
            scrollbar-width: none;  /* Firefox */
            padding-left: 15vw;
            gap: 15vw;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                display: none;
            }

            .EV-item-container{
                display: grid;
                grid-template-columns: auto auto;
                grid-template-rows: auto;
                height: 100%;

                .EV-item-gfx-container{
                    grid-row: 1;
                    grid-column: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .EV-item-verticalBar{
                        background : linear-gradient(var.$light-neutrals-text-subtle, var.$light-neutrals-foreground-active);
                        margin-top: 20px;
                        height: 100%;
                        width: 1px;
                        overflow: hidden;

                        &.dark{
                            background : linear-gradient( var.$dark-neutrals-text-subtle, var.$dark-neutrals-foreground-active);
                        }
                    }
                }

                .EV-item-Container{
                    grid-row: 1;
                    grid-column: 2;
                    width: 16vw;
                    margin-top: 40px;
                    margin-left: 10px;

                    h4{
                        font-family: var.$font-familiy-raleway;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 22.4px;
                        color: var.$light-neutrals-text-default;
                        margin-top: 0px;
                        margin-bottom: 8px;

                        &.dark{
                            color: var.$dark-neutrals-text-default;
                        }
                    }

                    img{
                        max-width: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                        height: 170px;
                        width: 100%;
                    }

                    h3{
                        font-family: var.$font-familiy-raleway;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 22.4px;
                        color: var.$light-neutrals-text-default;
                        margin-top: 8px;
                        margin-bottom: 8px;

                        &.dark{
                            color: var.$dark-neutrals-text-default;
                        }
                    }

                    p{
                        font-family: var.$font-familiy-raleway;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22.4px;
                        color: var.$light-neutrals-text-default;
                        margin-bottom: 8px;

                        &.dark{
                            color: var.$dark-neutrals-text-default;
                        }
                    }

                    a{
                        cursor: pointer;
                        width: 100%;
                        height: 40px;
                        border-radius: 8px;
                        gap: 8px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        background-color: var.$light-secondary-foreground-default;
                        border: 1px solid var.$light-secondary-border-separator;
                        text-decoration: none;

                        &.dark{
                            background-color: var.$dark-secondary-foreground-default;
                            border: 1px solid var.$dark-secondary-border-separator;
                        }

                        

                        p{
                            margin: 0px;
                            height: min-content;
                            font-family: var.$font-familiy-raleway;
                            color: var.$light-secondary-text-subtle;
                            font-weight: 700;
                            font-size: 16px;
                            letter-spacing: 0.03em;

                            &.dark{
                                color: var.$dark-neutrals-text-subtle;
                            }
                            
                        }
                        
                        svg{
                            color: var.$light-secondary-text-subtle;

                            &.dark{
                                color: var.$dark-secondary-text-subtle;
                            }
                        }
                    }
                }
            }
        }
    }
    .EV-arrow-container{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 40px;
        margin-top: 40px;

        button{
            cursor: pointer;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: var.$light-secondary-foreground-default;

            width: 40px;
            height: 40px;
            border-radius: 100px;

            border: 1px solid var.$light-secondary-border-separator ;

            color: var.$light-secondary-text-subtle;

            &.dark{
                background-color: var.$dark-secondary-foreground-default;
                border: 1px solid var.$dark-secondary-border-separator;
                color: var.$dark-secondary-text-subtle;
            }
        }

        :nth-child(1) svg{
            transform: rotate(90deg);
        }
        :nth-child(2) svg{
            transform: rotate(-90deg);
        }
    }
}
}

@media screen and (max-width: 1000px) {
.EV{
    .EV-background-halfGlob{
        display: none;
    }
    .EV-main{
        .EV-main-container{
            .EV-horizontalBar{
                margin-left: 11px;
                width: 1px;
                height: 100%;
                background : linear-gradient(var.$light-neutrals-text-subtle, var.$light-neutrals-foreground-active)
            }
            .EV-list-container{
                display: flex;
                flex-direction: column;

                .EV-item-container{
                    width: 100%;
                    .EV-item-gfx-container{
                        display: flex;
                        flex-direction: row;
                        height: fit-content;
                        .EV-item-verticalBar{
                            display: none;
                        }
                    }
                    .EV-item-Container{
                        margin-top: 0px;
                        margin-left: 10%;
                        width: 90%;
                        max-width: 1000px;

                        img{
                            height: auto;
                            width: 100%;
                        }

                        a{
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }
            }
        }
        .EV-arrow-container{
            display: none;
        }
    }
    
}
}