@use "_variables" as var;

#POD-main{
    margin-top: 150px;
    margin-left: 7vw;
    margin-right: 7vw;
    display: grid;
    grid-template-columns: 15% 65%;
    column-gap: 15%;
    font-family: var.$font-familiy-raleway;
    color: var.$light-primary-text-default;

    &.dark{
        color: var.$dark-primary-text-default;
    }

    #POD-text{
        display: flex;
        flex-direction: column;
        max-width: 250px;
        color: var.$light-primary-text-default;

        button{
            cursor: pointer;
            background-color: var.$light-secondary-foreground-default;
            border: 1px solid var.$light-secondary-border-separator;
            color: var.$light-secondary-text-subtle ;
            font-size: 16px;
            border-radius: 8px;
            padding: 5px 10px 5px 10px;
            margin-bottom: 30px;

            &:hover{
                background-color: #E6EDFE;
            }
        }

        &.dark{
            color: var.$dark-primary-text-default;
        }

        img{
            border-radius: 8px;
            margin-bottom: 20px;
        }

        h2{
            border-top: 1px black solid;
            padding-top: 20px;
            margin: 0px;
            font-family: var.$font-family-compagnon;
            color: var.$light-primary-text-default;

            &.dark{
                border-top: 1px var.$dark-primary-text-default solid;
                color: var.$dark-neutrals-text-default;
            }
        }

        p{
            &.dark{
                color: var.$dark-neutrals-text-subtle;
            }
        }
    }

    #POD-trackList-container{
        display: grid;
        grid-template-columns: 100%;
        row-gap: 75px;
        margin-bottom: 150px;

        .POD-track-container{
            display: grid;
            grid-template-columns: 15% 65%;
            column-gap: 20px;
            background-color: transparent;
            border: none;

            img{
                grid-column: 1;
                grid-row: 1;
                border-radius: 200px;
                width: 100%;
            }

            h4{
                font-family: 'Compagnon', Arial, Helvetica, sans-serif;
                font-size: 16px;
                margin: 0 0 10px 0;
            }

            button:not(.APO-tag){
                display: flex;
                justify-content: center;
                align-items: center;
                color : var.$light-primary-text-subtle;
                background-color: var.$light-primary-background-subtle;
                padding : 8px 8px 8px 8px;
                border-radius: 99px;
                border: 1px solid var.$light-primary-foreground-primary-default;
                $border-width : 1px;
                -webkit-box-shadow: $border-width $border-width 0px $border-width var.$light-primary-border-default; 
                box-shadow: $border-width $border-width 0px $border-width var.$light-primary-border-default;
                cursor : pointer;
                transition: all 0.5s;
                font-weight: 700;
                margin-bottom: 10px;

                svg{
                    height: 14px;
                }

                &:hover{
                    background-color: var.$light-primary-foreground-hover ;
                }

            }
        }
    }
}

@media screen and (max-width: 1000px) {

    #POD-main{
        grid-template-columns: 100%;
        margin-top: 225px;
        display: flex;
        flex-direction: row;

        #POD-text{
            position:fixed;
            top: 100px;
            left: 7vw;
            background-color: white;
            padding: 8px;
            border-radius: 8px;
            flex-direction: row;

            &.dark{
                border: 1px solid var.$dark-secondary-border-default;
                color: var.$dark-neutrals-text-subtle; 
                background-color: var.$dark-secondary-background-default !important;
            }

            img{
                width: 75px;
                height: 75px;
                border-radius: 4px;
                margin: 0px;
            }

            h2{
                padding: 0px;
                border: 0px;
                font-size: 20px;
                margin-left: 10px;

                &.dark{
                    color: var.$dark-secondary-text-subtle;
                    border: 0px;
                }
            }

            p{
                display: none;
            }
        }
        
        #POD-trackList-container{
            .POD-track-container{
                display: flex;
                width: 85vw;
                flex-direction: column;
                align-items: center;

                img{
                    width: 150px;
                    margin-bottom: 10px;
                }

                .POD-track-in-container{

                    img{
                        display: none;
                    }

                    p{
                        font-size: 13px;
                    }

                }
            }
        }
            
    }


}