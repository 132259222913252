@use "_variables" as var;

.PLA-main-grid{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    position: fixed;
    bottom: 0px;
    width: 100vw;
    overflow: hidden;
}

.PLA-POD-background{
    grid-row: 1;
    grid-column: 1;
    z-index: 1;
    height: 100px;
    object-fit: fill;
    color: var.$light-secondary-foreground-active;

    &.dark{
        color: var.$dark-secondary-foreground-active;
    }
}

.PLA-background{
    grid-row: 1;
    grid-column: 1;
    z-index: 1;
    height: 100px;
    object-fit: fill;
    color: var.$light-secondary-foreground-default;

    &.dark{
        color: var.$dark-secondary-foreground-default;
    }
}

.PLA-container{
    z-index: 2;
    grid-row: 1;
    grid-column: 1;
    display: grid;
    grid-template-columns: 10% 50% auto;
    height: 100px;
    padding-left: 5vw;
    padding-right: 5vw;
    align-items: center;
    width: 90vw;

    .PLA-playBtn{
        font-size: 62px;
        background-color: transparent;
        padding: 0px;
        height: 62px;
        border: none;
        color: var.$light-secondary-foreground-primary-hover;
        margin-right: auto;

        &.dark{
            color: var.$dark-secondary-foreground-primary-hover;
        }
    }

    .PLA-trackInfo{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        font-family: var.$font-familiy-raleway;
        width: 30vw;

        p:nth-child(1){
            color: var.$light-secondary-text-default;
            font-weight: 400;

            &.dark{
                color: var.$dark-secondary-text-default;
            }
        }
        :nth-child(2){
            color: var.$light-secondary-foreground-primary-default;

            &.dark{
                color: var.$dark-secondary-foreground-primary-default;
            }
        }
        :nth-child(3){
            color: var.$light-secondary-text-subtle;
            font-weight: 700;

            &.dark{
                color: var.$dark-secondary-text-subtle;
            }
        }
    }

    .PLA-volumeController{
        margin-left: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg{
            color: var.$light-secondary-text-subtle;
            font-size: 20px;
            margin-right: 10px;

            &.dark{
                color: var.$dark-secondary-text-subtle;
            }
        }
        
        .PLA-volumeSlider{

            display: grid;
            grid-template-columns: 100%;
            grid-template-rows: 100%;

            #volume-slider{
                grid-column: 1;
                grid-row: 1;
                height: 8px;
                width: 100%;
                background-color: var.$light-secondary-foreground-primary-default;
                z-index: 0;
                border-radius: 8px;
                box-shadow: 1px 2px 4px var.$light-secondary-foreground-primary-default ;

                &.dark{
                    background-color: var.$dark-secondary-foreground-primary-default;
                    box-shadow: 1px 2px 4px var.$dark-secondary-foreground-primary-default ;
                }
            }

            input[type=range]{
                grid-column: 1;
                grid-row: 1;
                -webkit-appearance: none;     /*nécessaire pour Chrome */
                padding: 0;                   /* nécessaire pour IE */
                font: inherit;                /* même rendu suivant font document */
                outline: none;
                color: transparent;                  /* sert pour couleur de référence, via currentColor, pour le curseur */
                opacity: 0;
                box-sizing: border-box;       /* même modèle de boîte pour tous */
                transition: opacity .2s;
                cursor: pointer;
                margin: 0px;
                z-index: 1;
                height: 8px;
            }

            #background-volumeSlider{
                grid-column: 1;
                grid-row: 1;
                z-index: -1;
                border-radius:8px ;
                height: 8px;
                width: 100%;
                box-shadow: inset 0px 3px 5px rgba(16, 29, 70, 0.2);
                background: var.$light-secondary-foreground-active ;/* sert pour couleur de fond de la zone de déplacement */

                &.dark{
                    background: var.$dark-secondary-foreground-active ;/* sert pour couleur de fond de la zone de déplacement */
                }
            }

            input[type=range]::-webkit-slider-thumb {
                -webkit-appearance: none;       /* également nécessaire sur le curseur */
                width: 1em;
                height: inherit;                /* s'adapte à la hauteur de l'input */
                border: none;
                border-radius: 0;               /* pris en compte sur Webkit et Edge */
                background: transparent;       /* pris en compte sur Webkit only */
            }
            input[type=range]::-moz-range-thumb {
                width: 1em;
                height: inherit;                /* s'adapte à la hauteur de l'input */
                border: none;                   /* supprimer la bordure */
                border-radius: 0;               /* supprimer le rayon */
                background: transparent;
            }
            input[type=range]::-ms-thumb {
                width: 1em;
                height: inherit;                /* s'adapte à la hauteur de l'input */
                border: none;                   /* supprimer la bordure */
                border-radius: 0;               /* supprimer le rayon */
                background: transparent;
            }
        }
    }
}

#PLA-podcast-main{
    position: fixed;
    bottom: 0px;
    width: 100vw;
    overflow: hidden;

    &.inactive{
        display: none;
    }

    .PLA-podcast-player{
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 100%;
        background-color: #f5f5f5 ;
        border-top: 1px rgb(206, 206, 206) solid;


        .PLA-podcast-soundcloud{
            grid-row: 1;
            grid-column: 1;
            z-index: 4;
            margin-top: auto;
            background-color: #f5f5f5 !important;
        }

        button{
            grid-row: 1;
            grid-column: 1;
            z-index: 4;
            width: 10%;
            height: 40px;
            border-radius: 8px;
            background-color: var.$light-neutrals-foreground-default;
            border: 1px solid var.$light-secondary-border-default;
            margin-left: 82.5%;
            margin-top: auto;
            margin-bottom: auto;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 10px;

            p{
                margin: 0px;
                font-family: var.$font-familiy-raleway;
                font-weight: 700;
                color: var.$light-secondary-text-subtle;
                font-size: 16px;
            }

            img{
                width: 20px;
                height: 15px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {

    #PLA-podcast-main{
        .PLA-podcast-player{
            button {
                margin-left: 75%;
                min-width: 93px;
                width: auto;
            }
        }

    }

    .PLA-main-grid{
        .PLA-container{
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            button{
                padding: 0px;
                margin-right: auto;
            }

            .PLA-trackInfo{
                margin: auto;
                width: 70vw;
            }

            .PLA-volumeController{
                display: none;
            }
        }
    }
}